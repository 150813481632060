// Here you can add other styles
.pointer {
  cursor: pointer;
}
.leaflet-container {
  width: 100%;
  height: 50vh;
  z-index: 0;
}

.text-white{
  color: white !important;
}

.profile-image{ width: 200px; height: 200px; object-fit: cover; }

.magnify-profile-image{transform: scale(1.5);}